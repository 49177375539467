<template>
  <div>
    <carousel
      :perPage="1"
      :paginationEnabled="false"
      :loop="true"
      :navigateTo="navigateTo"
    >
      <slide v-for="(slide, key) in alertBanner" :key="key">
        <div class="flex items-center justify-between px-5">
          <vs-icon
            icon="arrow_back_ios"
            size="small"
            class="mr-4 cursor-move"
            @click="handleBack(key)"
          ></vs-icon>
          
          <span
            @click="handleSelectBanner(slide)"
            class="mx-2 text-primary w-full text-center cursor-pointer hover:underline"
            >{{ slide.title }}</span
          >
         
          <vs-icon
            icon="arrow_forward_ios"
            size="small"
            class="mr-4 cursor-move"
            @click="handleNext(key)"
          ></vs-icon>
        </div>
      </slide>
    </carousel>
    <vs-popup
      class="learnMorePopup"
      :title="selectedBanner.title"
      :fullscreen="isMobileView ? true : false"
      :active.sync="learnMorePopupActive"
      :button-close-hidden="true"
    >
      <div class="p-5">
        <div
          class="text-center hidden md:block"
          v-if="selectedBanner.images && selectedBanner.images.desktop"
        >
          <img
            width="50%"
            :src="
              (selectedBanner.images && selectedBanner.images.desktop) || ''
            "
            alt="image"
          />
        </div>
        <div
          class="text-center block md:hidden"
          v-if="selectedBanner.images && selectedBanner.images.mobile"
        >
          <img
            width="50%"
            :src="(selectedBanner.images && selectedBanner.images.mobile) || ''"
            alt="image"
          />
        </div>
        <div class="text-center text-3xl my-8">{{ selectedBanner.title }}</div>
        <div
          class="alert-description"
          v-html="selectedBanner.description"
          style="font-size: 14px; margin-top: 4px"
        ></div>
        <div class="text-center mt-4">
          <vs-button
            :color="selectedBanner.alert_type"
            @click="learnMorePopupActive = false"
            >I Understand</vs-button
          >
        </div>
      </div>
    </vs-popup>
    <div class=" mt-5">
        <vs-button @click="handleRedirectEditPage">Edit Alert</vs-button>
    </div>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  data: () => ({
    alertId: "",
    selectedBanner: {},
    alertBanner: [],
    learnMorePopupActive: false,
    isMobileView: null,
    navigateTo: 0,
  }),
  methods: {
    ...mapActions("storeAlertNotification", [
      "getStoreAlertNotificationDetails",
    ]),
    async fetchAlertDetails() {
      try {
        const res = await this.getStoreAlertNotificationDetails(this.alertId);
        const tempData = [...res.data.data];
        tempData.map((alert) => {
          if (alert.alert_type === "info") alert.alert_type = "primary";
          if (alert.alert_type === "error") alert.alert_type = "danger";
          return alert;
        });
        this.alertBanner = tempData;
        this.$vs.loading.close();
      } catch (error) {
        this.$vs.loading.close();
        console.log(error.message);
      }
    },
    handleSelectBanner(details) {
      this.selectedBanner = { ...details };
      this.learnMorePopupActive = true;
    },
    myEventHandler() {
      this.isMobileView = window.innerWidth < 768;
    },
    handleBack(index) {
      if (index !== 0) this.navigateTo = this.navigateTo - 1;
    },
    handleNext(index) {
      if (index !== this.alertBanner.length - 1)
        this.navigateTo = this.navigateTo + 1;
    },
    handleRedirectEditPage(){
      this.$router.push({
          name: "SuperAdminAlertDetails",
          params: {
            alertId: this.alertId
          }
      });
    },
  },
  computed: {
    toggleScreenSize: function () {
      if (this.isMobileView) {
        return !this.learnMorePopupActive;
      }
      return true;
    },
  },
  created() {
    this.alertId = this.$route.params.alertId;
    this.fetchAlertDetails();
  },
  mounted() {
    window.addEventListener("resize", this.myEventHandler);
    this.myEventHandler();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>

<style lang="scss">
.learnMorePopup .vs-popup {
  width: 50%;
  overflow-wrap: break-word;
}

.con-vs-popup.fullscreen .vs-popup {
  max-width: 100%;
  max-height: 100%;
  margin: 0;
}

.alert-description {
    ul,
    ol {
      padding-left: 2.5rem;
    }
    ul {
      list-style-type: initial;
    }
    ol {
      list-style-type: auto;
    }
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    address {
      display: block;
      margin-inline-start: 0;
      margin-inline-end: 0;
      unicode-bidi: isolate;
    }
    p {
      margin-block: 1em;
    }
    h1 {
      font-size: 2em;
      margin-block: 0.67em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.5em;
      margin-block: 0.83em;
    }
    h3 {
      font-size: 1.17em;
      margin-block: 1em;
    }
    h4 {
      margin-block: 1.33em;
    }
    h5 {
      font-size: 0.83em;
      margin-block: 1.67em;
    }
    h6 {
      font-size: 0.67em;
      margin-block: 2.33em;
    }
    pre {
      font-family: monospace;
      white-space: pre;
      margin: 1em 0;
    }
    address {
      font-style: italic;
    }
  }
  
